import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .top-faq-wrapper {
    .top-faq {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top-faq-header {
        margin-bottom: 24px;
      }

      .top-faq-collapse-wrapper {
        width: 100%;
        max-width: 673px;
      }

      .top-faq-link {
        margin-top: 32px;
      }
    }
    .divider {
      background-color: ${COLORS.primary};
      height: 3px;
      margin: 16px auto 0;
    }
  }
  .top-faq-note {
    margin: 48px auto 0;
    max-width: 673px;
    text-indent: -1em;
    padding-left: 1em;
  }
`;

export default styles;
