import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .plan-cards-wrap {
    max-width: 448px;
    margin: 0 auto;
    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      max-width: initial;
    }
  }
  .plan-cards {
    display: flex;
    justify-content: center;
    flex-flow: column;
    gap: 24px;
    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      flex-flow: row;
    }
  }
  .plan-cards-note {
    margin-top: 24px;
    text-indent: -1em;
    padding-left: 1em;
    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      text-align: center;
    }
    .symbol {
      margin-right: 4px;
    }
  }
`;

export default styles;
