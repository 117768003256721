import useTranslation from 'next-translate/useTranslation';
import TopicsList from '@features/topics/components/TopicsList';
import { useGetTopics } from '@features/topics/hooks/useGetTopics';
import { useAppSelector } from '@hooks/useAppSelector';

const TopicsListContainer = () => {
  const { t } = useTranslation('browse');
  const { topic, topicCategories } = useAppSelector((state) => ({
    topic: state.topic,
    topicCategories: state.topicCategories,
  }));
  const categoryIds = topicCategories?.data
    ? topicCategories?.data.map((category) => category.id)
    : [];

  useGetTopics({
    categoryIds,
  });

  if (!topic.topics?.length) {
    return null;
  }

  return <TopicsList t={t} topics={topic.topics.slice(0, 6)} />;
};

export default TopicsListContainer;
