import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as topicActions from '@redux/modules/topic';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';

type Props = {
  categoryIds: number[];
  page?: number;
  perPage?: number;
};

export const useGetTopics = ({ categoryIds, page = 1, perPage = 8 }: Props) => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { getTopics } = useActions({
    ...topicActions,
  });

  useEffect(() => {
    getTopics(locale, page, perPage, categoryIds);
  }, [locale, categoryIds.length, page, perPage]);
};
