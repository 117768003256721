import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import Swiper from '@atoms/Swiper';
import { TypoMBold, TypoSBold, TypoXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import IconCoin from '@components/svg-icons/IconCoin';
import { useAppSelector } from '@hooks/useAppSelector';
import { BREAKPOINT } from '@utils/constants';
import routes from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import { uuid } from '@utils/utils';
import styles from './css';

const options = {
  breakpoints: {
    [BREAKPOINT.desktopL]: {
      slidesPerView: 4,
    },
    [BREAKPOINT.mobile]: {
      slidesPerView: 3,
    },
    [BREAKPOINT.mobileS]: {
      slidesPerView: 2.1,
    },
  },
  centeredSlides: false,
  loop: false,
  observer: true,
  slidesPerView: 1.5,
  spaceBetween: 16,
};

const FlightName: { [key: number]: { en: string; ja: string } } = {
  1: { en: 'Haneda - Sapporo(Shinchitose)', ja: '羽田 - 札幌(新千歳)' },
  20: { en: 'Haneda - Komatsu', ja: '羽田 - 小松' },
  22: { en: 'Haneda - Osaka(Itami)', ja: '羽田 - 大阪(伊丹)' },
  28: { en: 'Haneda - Okayama', ja: '羽田 - 岡山' },
  30: { en: 'Haneda - Hiroshima', ja: '羽田 - 広島' },
  36: { en: 'Haneda - Fukuoka', ja: '羽田 - 福岡' },
  39: { en: 'Haneda - Nagasaki', ja: '羽田 - 長崎' },
  40: { en: 'Haneda - Kumamoto', ja: '羽田 - 熊本' },
  50: { en: 'Haneda - Okinawa(Naha)', ja: '羽田 - 沖縄(那覇)' },
  7: { en: 'Haneda - Hakodate', ja: '羽田 - 函館' },
};

type Props = {
  customOptions?: SwiperOptions;
  name: string;
};

const FlightSlider = ({ customOptions = undefined, name }: Props) => {
  const { availableFlights, neighbor } = useAppSelector((state) => ({
    availableFlights: state.availableFlights,
    neighbor: state.neighbor,
  }));
  const { locale } = useRouter();
  const { t } = useTranslation('flight');
  const [swiperUniqueKey, setSwiperUniqueKey] = useState('');

  useEffect(() => {
    setSwiperUniqueKey(uuid());
  }, []);

  return (
    <div className="flight-list-slider">
      <div className="flight-list-title">
        <TypoMBold text={t('flightSlider.title')} />
        <Link
          className="flight-list-more"
          href={routes.v2FlightReservationsNew}
        >
          <TypoXSBold color="purple600" text={t('flightSlider.searchMore')} />
        </Link>
      </div>
      <div className="flight-list-slider-container">
        <Swiper
          hasSwiperButton={true}
          key={swiperUniqueKey}
          name={name + swiperUniqueKey}
          options={customOptions || options}
        >
          {availableFlights?.map((flight, index) => (
            <SwiperSlide key={flight.arrival_airport_id}>
              <div className="swiper-slide flight-list-slider-item">
                <Link
                  href={`${routes.v2FlightReservationsNew}?departure_airport_id=${flight.departure_airport_id}&arrival_airport_id=${flight.arrival_airport_id}`}
                  target="_blank"
                  onClick={() =>
                    pushDataLayer({
                      campaign_code: '',
                      event: 'gtm-click',
                      event_name: `button_flight-list-${index}`,
                      neighbor_id: neighbor?.id,
                    })
                  }
                >
                  <div className="flight-list-image-container">
                    <div className="flight-list-image">
                      <Image
                        alt="発着空港画像"
                        fill={true}
                        src={`/images/flight/flight-list/thumbnail-${flight.arrival_airport_id}.png`}
                      />
                    </div>
                  </div>
                  <div className="flight-list-box">
                    <div className="flight-list-box-inner">
                      <h3 className="flight-list-name">
                        {locale === 'ja'
                          ? FlightName?.[flight.arrival_airport_id]?.ja
                          : FlightName?.[flight.arrival_airport_id]?.en}
                      </h3>
                      <div className="flight-list-box-coin">
                        <IconCoin size="sm" />
                        <TypoSBold
                          text={`${
                            flight.coin_j_adult || flight.coin_y_adult
                          }~`}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default FlightSlider;
