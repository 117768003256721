import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .topic-section {
    max-width: 1232px;
    margin: 0 auto 16px;
    padding: 48px 16px 0;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      margin: 0 auto;
      padding: 48px 40px 0;
    }
  }
`;

export default styles;
