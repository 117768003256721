import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import Button from '@atoms/Button';
import InviteDescriptionItem from '@atoms/InviteDescriptionItem';
import {
  TypoLBold,
  TypoMBold,
  TypoSBold,
  TypoXSBold,
  TypoXXLBold,
  TypoXXXS,
  TypoXXXXLBold,
} from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import { useIsSignUpCampaignActive } from '@hooks/useCampaign';
import { useIsSignUpAvailable } from '@hooks/useIsSignUpAvailable';
import NeighborPlans from '@molecules/NeighborPlans';
import { INVITEE_COIN, INVITEE_RETENTION_COIN } from '@utils/constants';
import { useIsJapanUser } from '@utils/hooks';
import routes from '@utils/routes';
import styles from './css';

type Props = {
  showReferralCodeBonus?: boolean;
};

const ConvertSection = ({ showReferralCodeBonus = false }: Props) => {
  const isJapanUser = useIsJapanUser();
  const { t: topT } = useTranslation('top');
  const { t } = useTranslation('lp');

  const isSignUpAvailable = useIsSignUpAvailable();
  const isSignUpCampaignActive = useIsSignUpCampaignActive();

  const inviteeCoin = INVITEE_COIN + (isSignUpCampaignActive ? 100 : 0);
  const totalCoin = inviteeCoin + INVITEE_RETENTION_COIN;

  if (isJapanUser) {
    return (
      <div className="signup-campaign-cta-cont">
        <div className="signup-campaign-cta-cont-title-top">
          <Mobile>
            <TypoLBold color="white">
              <Trans
                components={{
                  br: <br />,
                  strong: <TypoLBold color="pink500" />,
                }}
                i18nKey="top:benefitSection.title"
                values={{
                  coin: totalCoin,
                }}
              />
              <TypoLBold color="white" text="*" />
            </TypoLBold>
          </Mobile>
          <Desktop>
            <TypoXXLBold color="white">
              <Trans
                components={{
                  br: <br />,
                  strong: <TypoXXLBold color="pink500" />,
                }}
                i18nKey="top:benefitSection.title"
                values={{
                  coin: totalCoin,
                }}
              />
              <TypoXXLBold color="white" text="*" />
            </TypoXXLBold>
          </Desktop>
        </div>
        <div className="signup-campaign-cta-inner">
          <InviteDescriptionItem
            backgroundColor="white"
            color="purple500"
            label={topT('benefitSection.invitationBonus.title')}
          >
            <div className="desc-wrapper">
              <div className="desc-detail">
                <div className="desc-content">
                  <div className="content-card">
                    <TypoSBold>
                      {topT('benefitSection.invitationBonus.subTitle')}
                    </TypoSBold>
                    <div className="given-coin">
                      <Trans
                        components={{
                          bold: <TypoXXXXLBold color="pink500" />,
                          typo: <TypoMBold />,
                        }}
                        i18nKey={
                          'top:benefitSection.invitationBonus.inviteeCoin'
                        }
                        values={{
                          coin: inviteeCoin,
                        }}
                      />
                    </div>
                    <div>
                      <Trans
                        components={{
                          typo: <TypoXSBold />,
                        }}
                        i18nKey={'top:benefitSection.invitationBonus.worth'}
                        values={{
                          coin: (inviteeCoin * 33).toLocaleString(),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InviteDescriptionItem>
          <InviteDescriptionItem
            backgroundColor="white"
            color="purple500"
            label={topT('benefitSection.loyaltyBonus.title')}
          >
            <div className="desc-wrapper">
              <div className="desc-detail">
                <div className="desc-content">
                  <div className="content-card">
                    <TypoSBold
                      text={topT('benefitSection.loyaltyBonus.subTitle')}
                    />
                    <div className="given-coin">
                      <Trans
                        components={{
                          bold: <TypoXXXXLBold color="pink500" />,
                          typo: <TypoMBold />,
                        }}
                        i18nKey={'top:benefitSection.loyaltyBonus.inviteeCoin'}
                        values={{
                          coin: INVITEE_RETENTION_COIN,
                        }}
                      />
                    </div>
                    <div>
                      <Trans
                        components={{
                          typo: <TypoXSBold />,
                        }}
                        i18nKey={'top:benefitSection.loyaltyBonus.worth'}
                        values={{
                          coin: '4,950',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InviteDescriptionItem>
        </div>
        <div className="signup-campaign-notice">
          <TypoXXXS color="white" text="*" />
          <TypoXXXS color="white" text={topT('benefitSection.note')} />
        </div>
        <style jsx={true}>{styles}</style>
      </div>
    );
  }

  return (
    <div className="convert-section">
      <div className="convert-section-body">
        <h2 className="convert-heading">
          <Mobile>
            <TypoMBold text={t('choosePlan')} />
          </Mobile>
          <Desktop>
            <TypoLBold text={t('choosePlan')} />
          </Desktop>
        </h2>
        <div className="neighbor-plans-wrapper">
          <NeighborPlans
            showAboutCoin={false}
            showNotice={false}
            showReferralCodeBonus={showReferralCodeBonus}
          />
        </div>
        <div className="call-to-action-button">
          {isSignUpAvailable ? (
            <Button
              label={
                showReferralCodeBonus
                  ? t('convertSectionButton')
                  : t('registerNow')
              }
              link={routes.signup}
              size="large"
              width="responsive"
            />
          ) : (
            <Button
              disabled={true}
              label={t('registerNow')}
              size="large"
              width="responsive"
            />
          )}
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default ConvertSection;
