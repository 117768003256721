import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .topics-list {
    .topics-list-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .topics-list-item {
        flex: 0 1 31.5%;
        margin-bottom: 48px;
        border-radius: 8px;
        box-shadow: 0 4px 4px -2px rgba(24, 39, 75, 0.08);
        transition: all 0.3s;
        &:hover {
          box-shadow: 0 8px 8px -2px rgba(24, 39, 75, 0.12);
        }
        @media (max-width: ${BREAKPOINT.tablet}px) {
          flex: 0 1 48.5%;
        }
        @media (max-width: ${BREAKPOINT.mobile}px) {
          flex: 0 1 100%;
          margin-bottom: 16px;
        }
      }
    }
    :global(.section-header) {
      padding-left: 0;
    }
  }
  .topics-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
`;

export default styles;
