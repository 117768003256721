import { Translate } from 'next-translate';
import Topic from '@atoms/Topic';
import { TypoMBold, TypoXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import routes from '@utils/routes';
import type * as Types from '@utils/types';
import styles from './css';

type Props = {
  t: Translate;
  topics: Types.Topic[];
};

const TopicsList = ({ t, topics }: Props) => (
  <div className="topics-list">
    <div className="topics-list-header">
      <TypoMBold>{t('topics')}</TypoMBold>
      <Link href={routes.topics}>
        <TypoXSBold color="purple600">{t('viewAll')}</TypoXSBold>
      </Link>
    </div>
    <ul className="topics-list-container">
      {topics.map((topic) => (
        <li className="topics-list-item" key={topic.id}>
          <Topic showBodyText={false} topic={topic} />
        </li>
      ))}
    </ul>
    <style jsx={true}>{styles}</style>
  </div>
);

export default TopicsList;
