import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .plan-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border: 3px solid ${COLORS.black300};
    border-radius: 16px;
    background-color: ${COLORS.white};
    @media screen and (min-width: ${BREAKPOINT.mobile}px) {
      width: 348px;
    }
    .mt-16 {
      margin-top: 16px;
    }
  }
  .plan-card-price {
    margin-top: 4px;
    text-align: center;
  }
  .plan-card-price-note {
    margin-top: 4px;
  }
  .plan-card-coin {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 16px;
    flex-grow: 1;
  }
  .ml-2 {
    margin-left: 2px;
  }
`;

export default styles;
