import classNames from 'classnames';
import { TypoXXSBold } from '@atoms/Typos';
import styles from './css';

type Props = {
  label: JSX.Element | string;
  theme: 'gray' | 'pink' | 'purple';
};

const PlanCardLabel = ({ label, theme }: Props) => (
  <div className={classNames('plan-card-label', `theme-${theme}`)}>
    <TypoXXSBold>{label}</TypoXXSBold>
    <style jsx={true}>{styles}</style>
  </div>
);

export default PlanCardLabel;
