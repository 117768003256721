import { FC } from 'react';
import moment from 'moment';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from '@components/Link';
import { useNeighbor } from '@hooks/useNeighbor';
import routes, { getUrlWithLocale, route } from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import { getTopicThumbnail } from '@utils/topics';
import styles from './css';

type Props = {
  showBodyText: boolean;
  topic: any;
};

const escape = (string_: string) =>
  string_.replaceAll(/(<([^>]+)>)/gi, '').replaceAll(/&hellip;/gi, '…');

const Topic: FC<Props> = ({ showBodyText = false, topic }) => {
  const { date, excerpt, id, title } = topic;
  const router = useRouter();
  const { neighbor } = useNeighbor();

  const onClickTopic = () => {
    pushDataLayer({
      campaign_code: '',
      event: 'gtm-click',
      event_name: `top_list_topics_${id}`,
      neighbor_id: neighbor?.id,
    });
  };

  return (
    <div className="topic">
      <Link
        href={getUrlWithLocale(
          router,
          router.locale,
          route(routes.topic, { id })
        )}
        onClick={onClickTopic}
      >
        <>
          <div className="topic-image-container">
            <div className="topic-image">
              <Image
                alt={title}
                fill={true}
                src={getTopicThumbnail(topic)}
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="topic-box">
            <p className="topic-date">{moment(date).format('YYYY.MM.DD')}</p>
            <h3 className="topic-title">{escape(title.rendered)}</h3>
            {showBodyText && (
              <p className="topic-excerpt">{escape(excerpt.rendered)}</p>
            )}
          </div>
        </>
      </Link>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default Topic;
