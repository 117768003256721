import useTranslation from 'next-translate/useTranslation';
import { TypoXXXS } from '@atoms/Typos';
import PlanCardContainer from '@features/plan-cards/containers/PlanCardContainer';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import { NeighborPlan } from '@services/hafh/types/generated';
import styles from './css';

type Props = {
  isOnlyPlan: boolean;
  plans: NeighborPlan[];
};

const PlanCards = ({ isOnlyPlan, plans }: Props) => {
  const { t } = useTranslation('neighbor-plan');
  const { isRegistrationCountryJapan } = useRegistrationCountry();

  return (
    <div className="plan-cards-wrap">
      <div className="plan-cards">
        {plans.map((plan) => (
          <PlanCardContainer
            isOnlyPlan={isOnlyPlan}
            key={plan.id}
            plan={plan}
          />
        ))}
      </div>
      {isRegistrationCountryJapan && (
        <div className="plan-cards-note">
          <TypoXXXS>
            <span className="symbol">*</span>
            {t('noteForBasicPlan')}
          </TypoXXXS>
        </div>
      )}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PlanCards;
