import TopicsListContainer from '@features/topics/containers/TopicsListContainer';
import styles from './css';

const TopicSection = () => {
  return (
    <div className="topic-section">
      <TopicsListContainer />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TopicSection;
