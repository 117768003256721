import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import * as availableFlightsActions from '@redux/modules/availableFlights';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

export const useGetAvailableFlights = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { isJapan } = useLocation();

  const { authUser, loadingAuthUser, loadingNeighbor, neighbor } =
    useAppSelector((state) => ({
      authUser: state.authUser,
      loadingAuthUser: state.loadingAuthUser,
      loadingNeighbor: state.loadingNeighbor,
      neighbor: state.neighbor,
    }));
  const { getAvailableFlights } = useActions({
    ...availableFlightsActions,
  });

  useEffect(() => {
    if (loadingAuthUser || loadingNeighbor) {
      return;
    }

    if (isEmpty(neighbor)) {
      return;
    }

    if (isJapan) {
      getAvailableFlights(locale, authUser);
    }
  }, [loadingAuthUser, loadingNeighbor, locale, isJapan]);
};
