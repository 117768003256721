import FlightSlider from '@features/flights/components/FlightSlider';
import { useGetAvailableFlights } from '@features/flights/hooks/useGetAvailableFlights';
import { useAppSelector } from '@hooks/useAppSelector';

const FlightSliderContainer = () => {
  const { availableFlights } = useAppSelector((state) => ({
    availableFlights: state.availableFlights,
  }));

  useGetAvailableFlights();

  if (!availableFlights?.length) {
    return null;
  }

  return <FlightSlider name="flight-information" />;
};

export default FlightSliderContainer;
