import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .section-header {
    .section-header-cont {
      display: flex;
      align-items: end;
      justify-content: space-between;
    }
    .section-header-heading {
      white-space: pre-wrap;
      font-size: 20px;
      font-weight: bold;
      @media screen and (max-width: ${BREAKPOINT.mobile}px) {
        font-size: 20px;
      }
    }
    .section-header-description {
      margin-top: 12px;
    }
  }
`;

export default styles;
