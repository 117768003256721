import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .topic {
    background-color: ${COLORS.white};
    border-radius: 8px;
    .topic-image-container {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      overflow: hidden;
      .topic-image {
        padding-top: 56%;
        position: relative;
      }
    }
    .topic-box {
      margin-top: 12px;
      margin-left: 12px;
      margin-right: 12px;
      padding-bottom: 12px;
      .topic-date {
        color: ${COLORS.black900};
        font-size: 14px;
        @media (max-width: ${BREAKPOINT.mobile}px) {
          font-size: 12px;
        }
      }
      .topic-title {
        font-weight: bold;
        font-size: 16px;
        color: ${COLORS.black};
        line-height: 1.8em;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .topic-excerpt {
        margin-top: 8px;
        color: ${COLORS.black700};
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
`;

export default styles;
