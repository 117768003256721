import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .flight-list-slider {
    .flight-list-title {
      margin-bottom: 16px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    :global(.flight-list-more .typo) {
      white-space: nowrap;
    }
    .flight-list-slider-container {
      :global(.swiper) {
        padding-bottom: 12px; /* for box-shadow */
        padding-left: 16px;
        padding-right: 16px;
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
          position: absolute;
          top: calc(50% - 24px);
          width: 40px;
          height: 40px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          transition: all 0.3s;
          opacity: 0.35;
          &:hover {
            opacity: 1;
          }
        }
        :global(.swiper-button-disabled) {
          display: none;
        }
        :global(.swiper-button-next) {
          right: 32px;
        }
        :global(.swiper-button-prev) {
          left: 32px;
          @media (max-width: ${BREAKPOINT.desktop}px) {
            display: none;
          }
        }
      }
      .flight-list-image-container {
        overflow: hidden;
        position: relative;
        .flight-list-image {
          aspect-ratio: 212/138;
          width: 100%;
          @media (min-width: ${BREAKPOINT.desktop}px) {
            aspect-ratio: 229/138;
          }
          @media (min-width: ${BREAKPOINT.desktopL}px) {
            aspect-ratio: 296/138;
          }
        }
      }
      .flight-list-box {
        padding: 12px;
        background: ${COLORS.white};
        .flight-list-box-inner {
          width: 100%;
        }
        .flight-list-box-coin {
          width: 100%;
          display: flex;
          margin-top: 4px;
          gap: 2px;
          align-items: center;
        }
        .flight-list-name {
          font-size: 14px;
          color: ${COLORS.black900};
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          -webkit-box-orient: vertical;
        }
      }
    }
    .flight-list-slider-item {
      cursor: pointer;
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.3s;
      box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
      &:hover {
        box-shadow: 0 8px 8px -2px rgba(24, 39, 75, 0.12);
      }
    }
  }
`;

export default styles;
