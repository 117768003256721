import { FC } from 'react';
import PlanCard from '@features/plan-cards/components/PlanCard';
import { getPricePerCoin } from '@features/plan-cards/utils/getPricePerCoin';
import type { NeighborPlan } from '@services/hafh/types/generated';
import {
  useIsJapanUser,
  useIsTaiwanUser,
  useRecommendedPlan,
} from '@utils/hooks';

type Props = {
  isOnlyPlan: boolean;
  plan: NeighborPlan;
};

const PlanCardContainer: FC<Props> = ({ isOnlyPlan, plan }) => {
  const isJapanUser = useIsJapanUser();
  const isTaiwanUser = useIsTaiwanUser();
  const recommendedPlan = useRecommendedPlan();
  const isCampaign = plan.first_month_price < plan.monthly_price;
  const pricePerCoin = getPricePerCoin(plan.monthly_price, plan.coin);

  const getCardType = () => {
    if (isTaiwanUser) {
      if (isCampaign) {
        return 'campaign';
      }
      return 'recommend';
    }
    if (isCampaign) {
      return 'campaign';
    }
    if (!isOnlyPlan && recommendedPlan && plan.id === recommendedPlan.id) {
      return 'recommend';
    }
    return 'basic';
  };

  return (
    <PlanCard
      cardType={getCardType()}
      isOnlyPlan={isOnlyPlan}
      plan={plan}
      pricePerCoin={pricePerCoin}
      showAsteriskForBasicPlan={isJapanUser && getCardType() === 'basic'}
    />
  );
};

export default PlanCardContainer;
