import Trans from 'next-translate/Trans';
import IconCoin from '@assets/icon-coin-new.svg';
import {
  TypoMBold,
  TypoXLBold,
  TypoXS,
  TypoXSBold,
  TypoXXS,
} from '@atoms/Typos';
import PlanCardLabelContainer from '@features/plan-cards/containers/PlanCardLabelContainer';
import type { NeighborPlan } from '@services/hafh/types/generated';
import { useIsJapanUser } from '@utils/hooks';
import styles from './css';

type Props = {
  cardType: 'basic' | 'campaign' | 'recommend';
  isOnlyPlan: boolean;
  plan: NeighborPlan;
  pricePerCoin: number;
  showAsteriskForBasicPlan: boolean;
};

const PlanCard = ({
  cardType,
  isOnlyPlan,
  plan,
  pricePerCoin,
  showAsteriskForBasicPlan,
}: Props) => {
  const isJapanUser = useIsJapanUser();

  return (
    <div className="plan-card">
      <PlanCardLabelContainer
        cardType={cardType}
        isOnlyPlan={isOnlyPlan}
        plan={plan}
      />
      <div className="mt-16" />
      <div>
        <TypoMBold tag="p">
          {plan.name}
          {showAsteriskForBasicPlan && <>*</>}
        </TypoMBold>
      </div>
      <div className="plan-card-price">
        <TypoMBold>
          {cardType === 'campaign' ? (
            <Trans
              components={{
                large: <TypoXLBold color="pink500" />,
                small: <span className="ml-2" />,
              }}
              i18nKey={'neighbor-plan:campaignPrice'}
              values={{
                currencySymbol: plan.currency.symbol,
                price: plan.first_month_price.toLocaleString(),
              }}
            />
          ) : (
            <Trans
              components={{
                large: <TypoXLBold />,
                small: <span className="ml-2" />,
              }}
              i18nKey={'neighbor-plan:pricePerMonth'}
              values={{
                currencySymbol: plan.currency.symbol,
                price: plan.first_month_price.toLocaleString(),
              }}
            />
          )}
        </TypoMBold>
        {cardType === 'campaign' && (
          <div className="plan-card-price-note">
            <Trans
              components={{ typo: <TypoXS tag="p" /> }}
              i18nKey={'neighbor-plan:regularPrice'}
              values={{
                currencySymbol: plan.currency.symbol,
                regularPrice: plan.monthly_price.toLocaleString(),
              }}
            />
          </div>
        )}
      </div>
      <div className="plan-card-coin">
        <div>
          <IconCoin />
        </div>
        <TypoXSBold>
          <Trans
            i18nKey="neighbor-plan:coinPerMonth"
            values={{
              coin: plan.coin,
            }}
          />
        </TypoXSBold>
        {isJapanUser && (
          <TypoXXS>
            <Trans
              i18nKey="neighbor-plan:pricePerCoin"
              values={{
                currencySymbol: plan.currency.symbol,
                price: pricePerCoin,
              }}
            />
          </TypoXXS>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PlanCard;
