import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .plan-card-label {
    padding: 6px 16px;
    border-radius: 44.44px;
    background-color: ${COLORS.greyBackground};
    &.theme-pink {
      :global(.typo) {
        color: ${COLORS.pink600};
      }
      background-color: ${COLORS.pink100};
    }
    &.theme-purple {
      :global(.typo) {
        color: ${COLORS.purple700};
      }
      background-color: ${COLORS.purple100};
    }
    &.theme-gray {
      :global(.typo) {
        color: ${COLORS.black700};
      }
      background-color: ${COLORS.black200};
    }
  }
`;

export default styles;
